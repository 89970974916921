html,
body {
    margin: 0;
    padding: 0;
}

html,
body {
    overscroll-behavior: none;
    overscroll-behavior-x: none;
}

:root {
    --sab: env(safe-area-inset-bottom, 34px);
    --bottom-padding: calc(var(--sab, 12px) - 12px);
}

:root[data-theme='light'] {
    --index-primary-background: var(--primary-background, #fff);
    --icon-logo-color: var(--blue-background, #00234d);
    --index-text-secondary-color: var(--text-secondary-color, #647081);
    --index-inverted-background: var(--inverted-background, #001c3d);
    --index-icon-error-color: var(--icon-error-color, #e40038);
    --index-icon-success-color: var(--icon-success-color, #00ab5b);
    --index-icon-warning-color: var(--icon-warning-color, #e59335);
    --index-text-primary-color: var(--text-primary-color, #000c1a);
    --index-icon-constant-color: var(--icon-constant-color, #fff);
    --index-icon-fill-color: var(--icon-fill-color, #a7afbc);
    --index-controls-secondary-active-background: var(
        --controls-secondary-active-background,
        #e5e7ea
    );
    --index-controls-secondary-tap-background: var(
        --controls-secondary-tap-background,
        #c5cad3
    );
    --index-text-inverted-color: var(--text-inverted-color, #fff);
}

:root[data-theme='dark'] {
    --index-primary-background: var(--primary-background, #10131a);
    --icon-logo-color: #fff;
    --index-text-secondary-color: var(--text-secondary-color, #7d8899);
    --index-inverted-background: var(--inverted-background, #f3f4f6);
    --index-icon-error-color: var(--icon-error-color, #e40038);
    --index-icon-success-color: var(--icon-success-color, #0cb666);
    --index-icon-warning-color: var(--icon-warning-color, #f1a33b);
    --index-text-primary-color: var(--text-primary-color, #f3f4f6);
    --index-icon-constant-color: var(--icon-constant-color, #f3f4f6);
    --index-icon-fill-color: var(--icon-fill-color, #596476);
    --index-controls-secondary-active-background: var(
        --controls-secondary-active-background,
        #262f3b
    );
    --index-controls-secondary-tap-background: var(
        --controls-secondary-tap-background,
        #394353
    );
    --index-text-inverted-color: var(--text-inverted-color, #10131a);
}

html[data-is-loading='true'] .loading,
html[data-is-updating='true'] .loading,
html[data-service-worker-is-loading='true'] .loading {
    visibility: visible;
    opacity: 1;
}

html[data-is-loading='true'] .ptr--ptr,
html[data-is-updating='true'] .ptr--ptr,
html[data-service-worker-is-loading='true'] .ptr--ptr {
    position: absolute;
    z-index: 10000;
}

html[data-visibility='false'] .loading {
    transition: none;
}

html {
    --app-loading-hover-animatin: hover 3s infinite;
    --app-loading-hover-animatin-red: hover-red 3s infinite;
}

.ptr--ptr {
    align-items: center !important;
    box-shadow: none !important;
}

.ptr--content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ptr--text {
    display: none;
}

.ptr--box {
    margin: calc(24 / var(--default-font-size, 16) * 1rem) 0 calc(8 / var(--default-font-size, 16) * 1rem) 0 !important;
    padding: 0 !important;
}

.ptr--icon {
    width: calc(24 / var(--default-font-size, 16) * 1rem);
    height: calc(24 / var(--default-font-size, 16) * 1rem);
}

.ptr--icon svg {
    width: 100%;
    height: 100%;
    fill: var(--index-icon-fill-color);
}

.ptr--refresh .ptr--icon {
    transition: none;
    animation: loader-spin 1s infinite linear;
}

@keyframes loader-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading {
    position: fixed;
    z-index: 4000;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    background: var(--index-primary-background);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    visibility 0s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    padding-bottom 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    inset: 0;
}

.loading::after {
    content: '';
    position: absolute;
    top: -50vh;
    left: -50vw;
    z-index: 3900;
    width: 200vh;
    height: 200vh;
    background: var(--index-primary-background);
}

.loading .loading-logo {
    z-index: 4000;
    width: max-content;
    height: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .14s ease-in-out;
}


.christmas-hat__off,
.snow-container__off {
    display: none;
}
.loading .loading-logo__hide{
    opacity: 0;
}
.loading .loading-logo svg {
    width: 60vw;
    height: max-content;
    overflow: visible;
}


.loading .loading-logo__animation svg:first-child > svg {
    width: 100%;
    height: unset;
    overflow: visible;
    fill: var(--index-text-secondary-color);
}

.loading .loading-logo__animation  > svg>  path:nth-child(10),
.loading .loading-logo__animation  > svg>  path:nth-child(11) {
    fill: var(--index-text-secondary-color);
    animation: var(--app-loading-hover-animatin);
    animation-delay: 2.5s;
}

.loading .loading-logo__animation  > svg>  path:nth-child(12),
.loading .loading-logo__animation  > svg>  path:nth-child(13) {
    fill: var(--index-text-secondary-color);
    animation: var(--app-loading-hover-animatin);
    animation-delay: 3s;
}

.loading .loading-logo__animation  > svg  > path:nth-child(2) {
    fill: var(--index-text-secondary-color);
    animation: var(--app-loading-hover-animatin);
}

.loading .loading-logo__animation  > svg  > path:nth-child(3) {
    fill: var(--index-text-secondary-color);
    animation: var(--app-loading-hover-animatin);
    animation-delay: 0.5s;
}

.loading .loading-logo__animation  > svg  > path:nth-child(4) {
    fill: var(--index-text-secondary-color);
    animation: var(--app-loading-hover-animatin);
    animation-delay: 1s;
}

.loading .loading-logo__animation  > svg  > path:nth-child(5) {
    fill: var(--index-text-secondary-color);
    animation: var(--app-loading-hover-animatin);
    animation-delay: 1.5s;
}

.loading .loading-logo__animation  > svg  > path:nth-child(6) {
    fill: var(--index-text-secondary-color);
    animation: var(--app-loading-hover-animatin);
    animation-delay: 2s;
}

.loading .loading-logo__animation  > svg  > path:nth-child(7) {
    fill: var(--index-text-secondary-color);
    animation: var(--app-loading-hover-animatin);
    animation-delay: 2.5s;
}

.loading .loading-logo__animation  > svg  > path:nth-child(8) {
    fill: var(--index-text-secondary-color);
    animation: var(--app-loading-hover-animatin);
    animation-delay: 3s;
}

.loading .loading-logo__animation  > svg  > path:nth-child(9) {
    fill: #e40038;
    animation: var(--app-loading-hover-animatin-red);
    animation-delay: 1.5s;
}

@keyframes hover {
    0% {
        fill: var(--index-text-secondary-color);
    }

    50% {
        fill: var(--icon-logo-color);
        filter: drop-shadow(0 0 1px var(--index-text-secondary-color));
    }

    100% {
        fill: var(--index-text-secondary-color);
    }
}

@keyframes hover-red {
    0% {
        fill: #e40038;
    }

    50% {
        fill: #e40038;
        filter: drop-shadow(0 0 1px #e40038);
    }

    100% {
        fill: #e40038;
    }
}


.blocked-page {
    position: fixed;
    display: grid;
    z-index: 10000;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 4em;
    font-size: 1vw;
    background: linear-gradient(
        315.87deg,
        #e40038 -12.92%,
        #c20030 6.64%,
        #9b004a 24.57%,
        #3b005f 63.03%,
        #00234d 117.14%
    );
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content;
    gap: 0.5em;
}

.blocked-page-content-left {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}

.blocked-page-content-left > img {
    position: absolute;
    top: -8.5em;
    left: 0;
    width: unset;
    height: 50em;
    overflow: visible;
}

.blocked-page-content-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 3em;
}

.blocked-page-content {
    display: grid;
    grid-template-columns: 30% 70%;
    width: 100%;
}

.blocked-page-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.blocked-page-title {
    font: var(--title1-font);
    font-weight: 800;
    font-size: 14em;
    line-height: 0.8em;
    font-family: Montserrat, sans-serif;
    color: #fff;
    letter-spacing: 0.03em;
}

.blocked-page-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 65%;
    font-weight: 400;
    font-size: 3em;
    line-height: 1em;
    font-family: 'SF Pro Display', sans-serif;
    text-align: left;
    color: #fff;
    letter-spacing: 0.01em;
}

.notification-toast {
    position: fixed;
    top: calc(-60 / var(--default-font-size, 16) * 1rem);
    left: 0;
    z-index: 5002;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 8px;
    list-style: none;
    font-size: 14px;
    line-height: 1.5715;
    color: rgb(0 0 0 / 85%);
    font-variant: tabular-nums;
    animation: show-notification 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    /* visibility: visible; */

    /* transition: top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */
    pointer-events: none;
    font-feature-settings: 'tnum';
}

.notification-toast_active {
    top: calc(8 / var(--default-font-size, 16) * 1rem);
    animation: none;
}

.notification-toast_hide {
    /* top: calc(-60 / var(--default-font-size, 16) * 1rem); */

    /* visibility: hidden; */

    /* transition: top 0s 0.3s, visibility 0s 0.3s; */
    animation: hide-notification 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.notification-toast-content {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: calc(14 / var(--default-font-size, 16) * 1rem) calc(12 / var(--default-font-size, 16) * 1rem);
    text-align: unset;
    column-gap: var(--space2, calc(8 / var(--default-font-size, 16) * 1rem));
    background: var(--index-inverted-background);
    border-radius: calc(6 / var(--default-font-size, 16) * 1rem);
    box-shadow: 0 4px 5px rgb(0 0 0 / 14%), 0 1px 10px rgb(0 0 0 / 12%),
    0 2px 4px rgb(0 0 0 / 20%);
    grid-template-columns: max-content 1fr;
}

.notification-toast-icon {
    width: unset;
    height: calc(16 / var(--default-font-size, 16) * 1rem);
}

.notification-toast-icon svg {
    width: unset;
    height: 100%;
}

.notification-toast-icon__success svg path {
    fill: var(--index-icon-success-color);
}

.notification-toast-icon__error svg path {
    fill: var(--index-icon-error-color);
}

.notification-toast-icon__warning svg path {
    fill: var(--index-icon-warning-color);
}

.notification-toast-text {
    font: normal 500 calc(14 / var(--default-font-size, 16) * 1rem) / 114% var(--default-font-famaly, 'Roboto');
    color: var(--index-text-inverted-color);
}

.problem-screen {
    position: fixed;
    display: grid;
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    padding: var(--space2, calc(8 / var(--default-font-size, 16) * 1rem)) var(--space4, calc(16 / var(--default-font-size, 16) * 1rem)) calc(
        var(--space4, calc(16 / var(--default-font-size, 16) * 1rem)) +
        calc(var(--bottom-padding) +
        var(--space3,
        calc(12 / var(--default-font-size, 16) * 1rem)))
    ) var(--space4, calc(16 / var(--default-font-size, 16) * 1rem));
    background-color: var(--index-primary-background);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    inset: 0 0 0 100vw;
    grid-template-rows: max-content 1fr max-content;
}

.problem-screen_network {
    z-index: 9998;
}

.problem-screen_mf-error {
    z-index: 9999;
    grid-template-rows: 1fr max-content;
}

.problem-screen__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.problem-screen_show {
    left: 0;
    opacity: 1;
}

.problem-screen__close-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: max-content;
    padding: var(--space3, calc(12 / var(--default-font-size, 16) * 1rem)) 0;
}

.problem-screen__close-icon svg {
    width: initial;
    height: calc(24 / var(--default-font-size, 16) * 1rem);
}

.problem-screen__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(72 / var(--default-font-size, 16) * 1rem);
    height: calc(72 / var(--default-font-size, 16) * 1rem);
    margin-bottom: var(
        --space3,
        calc(12 / var(--default-font-size, 16) * 1rem)
    );
    background: var(--index-icon-fill-color);
    border-radius: 50%;
}

.problem-screen__icon svg {
    width: initial;
    height: calc(36 / var(--default-font-size, 16) * 1rem);
}

.problem-screen__icon svg path {
    fill: var(--index-icon-constant-color);
}

.problem-screen__title {
    margin-top: var(--space3, calc(12 / var(--default-font-size, 16) * 1rem));
    font: normal 700 calc(20 / var(--default-font-size, 16) * 1rem) / 120% var(--default-font-famaly, 'Roboto');
    text-align: center;
    color: var(--index-text-primary-color);
}

.problem-screen__description {
    margin-top: var(--space2, calc(12 / var(--default-font-size, 16) * 1rem));
    margin-bottom: var(
        --space3,
        calc(12 / var(--default-font-size, 16) * 1rem)
    );
    font: normal 400 calc(16 / var(--default-font-size, 16) * 1rem) / 124% var(--default-font-famaly, 'Roboto');
    text-align: center;
    color: var(--index-text-secondary-color);
}

.problem-screen__button button {
    width: 100%;
    height: calc(48 / var(--default-font-size, 16) * 1rem);
    padding: 0 var(--space6, calc(24 / var(--default-font-size, 16) * 1rem));
    font: normal 600 calc(16 / var(--default-font-size, 16) * 1rem) / 124% var(--default-font-famaly, 'Roboto');
    color: var(--index-text-primary-color);
    background-color: var(--index-controls-secondary-active-background);
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.problem-screen__button button:active {
    background-color: var(--index-controls-secondary-tap-background);
}

.pull-to-refresh-default {
    position: absolute;
    top: 200vh;
}

@keyframes hide-notification {
    0% {
        top: 8px;
        transform: scale(1);
        opacity: 1;
    }

    100% {
        top: 0;
        transform: scale(1.02);
        opacity: 0;
    }
}

@keyframes show-notification {
    0% {
        top: calc(-60 / var(--default-font-size, 16) * 1rem);

        /* transform: scale(1.02); */
        opacity: 0;
    }

    100% {
        top: calc(8 / var(--default-font-size, 16) * 1rem);

        /* transform: scale(1); */
        opacity: 1;
    }
}

